import React, { useEffect, useRef, useState } from 'react';
import { navigate } from 'gatsby-link';
import { useQueryParam } from 'gatsby-query-params';
import { connect, Provider } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withPrefix } from 'gatsby-link';
import moment from 'moment';
import {
  Button,
  Alert,
  TextField,
  InputAdornment,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputMask from 'react-input-mask';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import LaunchIcon from '@mui/icons-material/Launch';
import RefreshIcon from '@mui/icons-material/Refresh';
import SettingsIcon from '@mui/icons-material/Settings';
import { getSessionStorage, setSessionStorage } from '../../api/dataApi';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import {
  listPartner,
  listServiceAddressRequest,
  createServiceAddressRequest,
  cancelServiceAddressRequest
} from '../../api/partnerDataApi';
import { validateEmail } from '../../api/userAuthApi';

import * as webSocketActions from '../../redux/actions/webSocketActions';
import * as partnerDataActions from '../../redux/actions/partnerDataActions';

import GeneralPopup from '../GeneralPopup';
import AddressSearch from '../AddressSearch';

import './index.css';

function MIPartnerDashboard({
  siteConfig,
  webSocket,
  actions,
  authUser,
  partnerData
}) {
  const [partnerList, setPartnerList] = useState(null);
  const [serviceAddressRequestList, setServiceAddressRequestList] =
    useState(null);
  const [addServiceAddressRequest, setAddServiceAddressRequest] =
    useState(null);
  const [activeRoute, setActiveRoute] = useState('/partner-dashboard');
  const [showCancelServiceRequest, setShowCancelServiceRequest] =
    useState(null);

  useEffect(() => {
    listPartner().then((_partnerList) =>
      setPartnerList(
        _partnerList.filter((_partner) => _partner.BrandId === siteConfig.id)
      )
    );
    loadServiceAddressRequestList();
  }, []);

  function loadServiceAddressRequestList() {
    actions.pageLoading(true, 'Loading...');
    listServiceAddressRequest(0).then((_serviceAddressRequestList) => {
      actions.pageLoading(false);
      setServiceAddressRequestList(_serviceAddressRequestList);
    });
  }

  return partnerList && partnerList.length ? (
    <>
      <div className="inner">
        <div className="header">
          <div className="menu">
            <Button
              onClick={() =>
                setAddServiceAddressRequest({
                  PartnerID:
                    partnerList.length === 1 ? partnerList[0].PartnerID : 0
                })
              }>
              <AddCircleIcon /> New Move-in
            </Button>
          </div>
          <h2>Partner Dashboard</h2>

          {/* <div className="tabs-container">
            <Tabs
              value={activeRoute}
              onChange={(event, newValue) => {
                updateRoute(newValue);
              }}
              centered
              sx={{ margin: 'auto' }}>
              <Tab label={'Dashboard'} value={'/partner-dashboard'} />
              <Tab label="Media Kit" value={'/partner-media'} />
            </Tabs>
          </div> */}
        </div>

        <div className="campaign-list service-address-request-list">
          {serviceAddressRequestList && serviceAddressRequestList.length ? (
            <TableContainer component={Paper} style={{ maxHeight: '60vh' }}>
              <Table aria-label="simple table" stickyHeader={true}>
                <TableHead>
                  <TableRow>
                    <TableCell>Partner</TableCell>
                    <TableCell>Address</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Move-in Date</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {serviceAddressRequestList.map((serviceAddressRequest) => (
                    <TableRow key={serviceAddressRequest.Visitor}>
                      <TableCell component="td" scope="row">
                        {serviceAddressRequest.PartnerName}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {serviceAddressRequest.Address}{' '}
                        {serviceAddressRequest.Secondary
                          ? ' ' + serviceAddressRequest.Secondary
                          : ''}
                        <br />
                        {serviceAddressRequest.City},{' '}
                        {serviceAddressRequest.State}{' '}
                        {serviceAddressRequest.Zip}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {serviceAddressRequest.FirstName}{' '}
                        {serviceAddressRequest.LastName}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {moment(serviceAddressRequest.DateMoveIn).format(
                          'MM/DD/YYYY'
                        )}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {serviceAddressRequest.DateInvitationSent ? (
                          'Invitation Sent'
                        ) : (
                          <>
                            {' '}
                            <Button
                              variant="outlined"
                              size="small"
                              style={{ float: 'right', marginTop: '-5px' }}
                              onClick={() =>
                                setShowCancelServiceRequest({
                                  ...serviceAddressRequest
                                })
                              }>
                              Cancel
                            </Button>
                            Pending Review{' '}
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Alert severity="warning">
              No move-ins have been added.{' '}
              <Button onClick={() => setAddServiceAddressRequest({})}>
                Add Move-in
              </Button>
            </Alert>
          )}
          <Button
            className="refresh-button"
            variant="outlined"
            onClick={() => loadServiceAddressRequestList()}>
            <RefreshIcon /> Refresh
          </Button>
        </div>
      </div>

      {addServiceAddressRequest ? (
        <GeneralPopup
          title={'Add Move-in'}
          className={'service-address-request-popup'}
          message={
            addServiceAddressRequest.Address ? (
              <div className="service-address-request-form">
                <div className="selected-address">
                  <label>Selected Address</label>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setAddServiceAddressRequest({
                        ...addServiceAddressRequest,
                        Address: '',
                        City: '',
                        Secondary: '',
                        State: '',
                        Zip: ''
                      });
                    }}>
                    Change Address
                  </Button>
                  {addServiceAddressRequest.Address}{' '}
                  {addServiceAddressRequest.Secondary
                    ? ' ' + addServiceAddressRequest.Secondary
                    : ''}
                  <br />
                  {addServiceAddressRequest.City},{' '}
                  {addServiceAddressRequest.State}{' '}
                  {addServiceAddressRequest.Zip}
                </div>

                <FormControl variant="outlined" fullWidth required>
                  <InputLabel id="language-select-label" fullWidth>
                    Partner
                  </InputLabel>
                  <Select
                    fullWidth
                    value={addServiceAddressRequest.PartnerID}
                    label="Partner"
                    onChange={(event) =>
                      setAddServiceAddressRequest({
                        ...addServiceAddressRequest,
                        PartnerID: event.target.value
                      })
                    }>
                    {partnerList.map((partner) => (
                      <MenuItem value={partner.PartnerID}>
                        {partner.PartnerName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <TextField
                  label="First Name"
                  required
                  fullWidth
                  value={addServiceAddressRequest.FirstName}
                  onChange={(event) =>
                    setAddServiceAddressRequest({
                      ...addServiceAddressRequest,
                      FirstName: event.target.value
                    })
                  }
                />

                <TextField
                  label="Last Name"
                  required
                  fullWidth
                  value={addServiceAddressRequest.LastName}
                  onChange={(event) =>
                    setAddServiceAddressRequest({
                      ...addServiceAddressRequest,
                      LastName: event.target.value
                    })
                  }
                />

                <TextField
                  label="Email"
                  required
                  fullWidth
                  value={addServiceAddressRequest.Email}
                  type="email"
                  onChange={(event) =>
                    setAddServiceAddressRequest({
                      ...addServiceAddressRequest,
                      Email: event.target.value
                    })
                  }
                />

                <InputMask
                  mask="999-999-9999"
                  maskChar="_"
                  value={addServiceAddressRequest.Phone}
                  onChange={(event) =>
                    setAddServiceAddressRequest({
                      ...addServiceAddressRequest,
                      Phone: event.target.value
                    })
                  }>
                  {() => (
                    <TextField label={'Cell Phone'} type="tel" fullWidth />
                  )}
                </InputMask>

                <InputMask
                  mask="99/99/9999"
                  maskChar={'_'}
                  value={addServiceAddressRequest.DateMoveIn}
                  onChange={(event) =>
                    setAddServiceAddressRequest({
                      ...addServiceAddressRequest,
                      DateMoveIn: event.target.value
                    })
                  }>
                  {() => (
                    <TextField
                      required
                      label="Move-in Date"
                      inputProps={{ inputMode: 'numeric' }}
                    />
                  )}
                </InputMask>

                <div className="radio-container">
                  <p>
                    <strong>
                      Will this move-in rent or own at this address?
                    </strong>
                  </p>

                  <FormControl component="fieldset">
                    <RadioGroup
                      value={addServiceAddressRequest.Role}
                      onChange={(event) =>
                        setAddServiceAddressRequest({
                          ...addServiceAddressRequest,
                          Role: event.target.value,
                          ResidentStatusID: null
                        })
                      }>
                      <FormControlLabel
                        value="Owner"
                        className={
                          addServiceAddressRequest.Role === 'Owner'
                            ? 'selected'
                            : ''
                        }
                        control={<Radio color="primary" />}
                        label="Own"
                      />

                      <FormControlLabel
                        value="Renter"
                        className={
                          addServiceAddressRequest.Role === 'Renter'
                            ? 'selected'
                            : ''
                        }
                        control={<Radio color="primary" />}
                        label="Rent"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
                {/* 
                {addServiceAddressRequest.Role ? (
                  <div className="radio-container">
                    <p>
                      <strong>What is the status of this renter?</strong>
                    </p>

                    <FormControl component="fieldset">
                      <RadioGroup
                        value={addServiceAddressRequest.ResidentStatusID}
                        onChange={(event) =>
                          setAddServiceAddressRequest({
                            ...addServiceAddressRequest,
                            ResidentStatusID: event.target.value
                          })
                        }>
                        <FormControlLabel
                          value="1"
                          className={
                            addServiceAddressRequest.ResidentStatusID == 1
                              ? 'selected'
                              : ''
                          }
                          control={<Radio color="primary" />}
                          label={
                            <>
                              <strong>Applicant</strong> - This is an applicant
                              that needs to go through the application process
                            </>
                          }
                        />

                        <FormControlLabel
                          value="2"
                          className={
                            addServiceAddressRequest.ResidentStatusID == 2
                              ? 'selected'
                              : ''
                          }
                          control={<Radio color="primary" />}
                          label={
                            <>
                              <strong>Pending</strong> - This resident needs to prepare to move in on the data specified above
                            </>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                ) : null} */}
              </div>
            ) : (
              <>
                <p>
                  <strong>
                    To add a move-in, please enter the service address below.
                  </strong>
                </p>

                <AddressSearch
                  hideSearchBy
                  mode="address"
                  handleAddressSelection={(address) => {
                    setAddServiceAddressRequest({
                      ...addServiceAddressRequest,
                      Address: address.street_line,
                      Secondary: address.secondary ? address.secondary : null,
                      City: address.city,
                      State: address.state,
                      Zip: address.zipcode,
                      ready: true
                    });
                  }}
                />
              </>
            )
          }
          submitLabel={'Continue'}
          submitDisabled={
            addServiceAddressRequest.ready
              ? addServiceAddressRequest.FirstName &&
                addServiceAddressRequest.LastName &&
                addServiceAddressRequest.Email &&
                validateEmail(addServiceAddressRequest.Email) &&
                addServiceAddressRequest.DateMoveIn &&
                addServiceAddressRequest.DateMoveIn.indexOf('_') === -1 &&
                addServiceAddressRequest.Role
                ? false
                : true
              : addServiceAddressRequest.Address
              ? false
              : true
          }
          submitFunc={() => {
            createServiceAddressRequest({ ...addServiceAddressRequest }).then(
              (result) => {
                loadServiceAddressRequestList();
                setAddServiceAddressRequest(null);
              }
            );
          }}
          closeFunc={() => setAddServiceAddressRequest(null)}
          closeLabel={'Cancel'}
        />
      ) : null}

      {showCancelServiceRequest ? (
        <GeneralPopup
          title={'Cancel Move-in Request'}
          className={'service-address-request-cancel-popup'}
          message={
            <p style={{ marginTop: '20px' }}>
              Are you sure you want to cancel this request?
            </p>
          }
          submitLabel={'Confirm Cancellation'}
          submitFunc={() => {
            cancelServiceAddressRequest(
              showCancelServiceRequest.ServiceAddressRequestID
            ).then((result) => {
              setShowCancelServiceRequest(null);
              loadServiceAddressRequestList();
            });
          }}
          closeFunc={() => setShowCancelServiceRequest(null)}
          closeLabel={'Cancel'}
        />
      ) : null}
    </>
  ) : null;
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    siteConfig: state.commonData.siteConfig,
    authUser: state.authUser,
    partnerData: state.partnerData
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch),
      loadCampaignList: bindActionCreators(
        partnerDataActions.loadCampaignList,
        dispatch
      )
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MIPartnerDashboard);
