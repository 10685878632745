import * as types from './actionTypes';

import { listPartner, listUserCampaign } from '../../api/partnerDataApi';

export function campaignListUpdated(campaignList) {
  return { type: types.CAMPAIGN_LIST_UPDATED, campaignList };
}

export function loadCampaignList() {
  return function (dispatch, getState) {
    return listUserCampaign().then((_campaignList) => {
      dispatch(campaignListUpdated(_campaignList));

      return _campaignList;
    });
  };
}
