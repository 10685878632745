import React, { useEffect, useRef, useState } from 'react';
import { navigate } from 'gatsby-link';
import { useQueryParam } from 'gatsby-query-params';
import { connect, Provider } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withPrefix } from 'gatsby-link';
import moment from 'moment';
import {
  Button,
  Alert,
  TextField,
  InputAdornment,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import LaunchIcon from '@mui/icons-material/Launch';
import RefreshIcon from '@mui/icons-material/Refresh';
import SettingsIcon from '@mui/icons-material/Settings';
import { getSessionStorage, setSessionStorage } from '../../api/dataApi';
import { listOrder } from '../../api/partnerDataApi';

import * as webSocketActions from '../../redux/actions/webSocketActions';
import * as partnerDataActions from '../../redux/actions/partnerDataActions';

import LoginForm from '../LoginForm';
import SCPartnerDashboard from './SCPartnerDashboard';
import MIPartnerDashboard from './MIPartnerDashboard';

import './index.css';

function PartnerDashboard({
  siteConfig,
  webSocket,
  actions,
  authUser,
  partnerData
}) {
  return (
    <div className={'page-container partner-dashboard'}>
      <div className="page">
        {authUser && authUser.User && authUser.User.Token ? (
          siteConfig.id === 'sc' ? (
            <SCPartnerDashboard />
          ) : (
            <MIPartnerDashboard />
          )
        ) : (
          <div className="register-form">
            <p>
              <strong>
                To get started, you'll need to set up a user account so you can
                log in.
              </strong>
            </p>
            <p>
              Your partner account will be set up and tracked under this user
              account. This is also how you'll access your Partner Dashboard for
              tracking commissions.
            </p>
            <LoginForm />
          </div>
        )}
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    siteConfig: state.commonData.siteConfig,
    authUser: state.authUser,
    partnerData: state.partnerData
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch),
      loadCampaignList: bindActionCreators(
        partnerDataActions.loadCampaignList,
        dispatch
      )
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PartnerDashboard);
