import React, { useEffect, useRef, useState } from 'react';
import { navigate } from 'gatsby-link';
import { useQueryParam } from 'gatsby-query-params';
import { connect, Provider } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withPrefix } from 'gatsby-link';
import moment from 'moment';
import {
  Button,
  Alert,
  TextField,
  InputAdornment,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import LaunchIcon from '@mui/icons-material/Launch';
import RefreshIcon from '@mui/icons-material/Refresh';
import SettingsIcon from '@mui/icons-material/Settings';
import { getSessionStorage, setSessionStorage } from '../../api/dataApi';
import { listOrder } from '../../api/partnerDataApi';

import * as webSocketActions from '../../redux/actions/webSocketActions';
import * as partnerDataActions from '../../redux/actions/partnerDataActions';

import LoginForm from '../LoginForm';
import CampaignSetupForm from './CampaignSetupForm';

import './index.css';

function SCPartnerDashboard({
  siteConfig,
  webSocket,
  actions,
  authUser,
  partnerData
}) {
  const [addCampaign, setAddCampaign] = useState(null);
  const [activeRoute, setActiveRoute] = useState('/partner-dashboard');
  const [orderList, setOrderList] = useState(null);

  useEffect(() => {
    if (
      (!partnerData || !partnerData.campaignList) &&
      authUser &&
      authUser.User &&
      authUser.User.Token
    ) {
      actions.loadCampaignList();
    }
  }, [authUser]);

  useEffect(() => {
    if (
      partnerData &&
      partnerData.campaignList &&
      partnerData.campaignList.length
    ) {
      loadOrderList();
    }
  }, [partnerData]);

  function updateRoute(newRoute) {
    setActiveRoute(newRoute);

    window.history.pushState({}, '', newRoute);
  }

  function loadOrderList() {
    listOrder().then((_orderList) => setOrderList(_orderList));
  }

  return (
    <>
      {partnerData &&
      partnerData.campaignList &&
      partnerData.campaignList.length ? (
        <div className="inner">
          <div className="header">
            <div className="menu">
              <Button onClick={() => setAddCampaign({})}>
                <AddCircleIcon /> New Campaign
              </Button>
            </div>
            <h2>Partner Program</h2>

            <div className="tabs-container">
              <Tabs
                value={activeRoute}
                onChange={(event, newValue) => {
                  updateRoute(newValue);
                }}
                centered
                sx={{ margin: 'auto' }}>
                <Tab label={'Dashboard'} value={'/partner-dashboard'} />
                <Tab label="Media Kit" value={'/partner-media'} />
              </Tabs>
            </div>
          </div>

          <div className="campaign-list">
            <h3>
              {authUser && authUser.User && authUser.User.IsAdmin
                ? 'User'
                : 'Your'}{' '}
              Campaigns
            </h3>
            {partnerData.campaignList.map((campaign) => (
              <div className={'campaign'}>
                <IconButton onClick={() => setAddCampaign({ ...campaign })}>
                  <SettingsIcon />
                </IconButton>
                <h4>{campaign.CampaignName}</h4>
                <h5>
                  {campaign.DateStart && campaign.DateEnd
                    ? campaign.DateEnd > campaign.DateStart
                      ? moment(campaign.DateStart).format('M/DD/yyyy') +
                        ' - ' +
                        moment(campaign.DateEnd).format('M/DD/yyyy')
                      : moment(campaign.DateStart).format('M/DD/yyyy') + ' only'
                    : 'Ongoing Campaign'}
                </h5>
                <div className="data-point">
                  <label>Completed Orders</label>
                  {campaign.CountOrder}
                </div>
                <div className="data-point">
                  <label>Total Commissions</label>$
                  {campaign.TotalCommission.toFixed(2)}
                </div>
              </div>
            ))}
          </div>

          <div className="commission-list">
            {orderList && orderList.length ? (
              <TableContainer component={Paper} style={{ maxHeight: '60vh' }}>
                <Table aria-label="simple table" stickyHeader={true}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Visitor</TableCell>
                      <TableCell>Visit Date</TableCell>
                      <TableCell>Order Date</TableCell>
                      <TableCell>Commission</TableCell>
                      <TableCell>
                        Payment Received by {siteConfig.brand}
                      </TableCell>
                      <TableCell>Payment Paid to You</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orderList.map((order) => (
                      <TableRow key={order.Visitor}>
                        <TableCell
                          component="td"
                          scope="row"
                          style={{
                            borderLeftColor: '#' + order.Visitor
                          }}>
                          {order.Visitor}
                        </TableCell>
                        <TableCell component="td" scope="row">
                          {moment(order.DateVisited).format('MM/DD/YYYY')}
                        </TableCell>
                        <TableCell component="td" scope="row">
                          {order.DateOrdered
                            ? moment(order.DateOrdered).format('MM/DD/YYYY')
                            : '---'}
                        </TableCell>
                        <TableCell component="td" scope="row">
                          {order.AmountCommission
                            ? '$' + order.AmountCommission.toFixed(2)
                            : '---'}
                        </TableCell>
                        <TableCell component="td" scope="row">
                          {order.DateCommissionReceived
                            ? moment(order.DateCommissionReceived).format(
                                'MM/DD/YYYY'
                              )
                            : '---'}
                        </TableCell>
                        <TableCell component="td" scope="row">
                          {order.DateCommissionPaid
                            ? moment(order.DateCommissionPaid).format(
                                'MM/DD/YYYY'
                              )
                            : '---'}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Alert severity="warning">
                No visitors have completed an order using your promo code yet.
                Visit the Media Kit section above for ideas on how to advertise
                your campaigns.
              </Alert>
            )}
            <Button
              className="refresh-button"
              variant="outlined"
              onClick={() => loadOrderList()}>
              <RefreshIcon /> Refresh
            </Button>
          </div>
        </div>
      ) : (
        <div className="register-form">
          <CampaignSetupForm onComplete={() => actions.loadCampaignList()} />
        </div>
      )}

      {addCampaign ? (
        <Dialog
          open={true}
          aria-labelledby="form-dialog-title"
          className="partner-add-popup"
          fullWidth={true}>
          <DialogTitle id="form-dialog-title">
            <img
              src={withPrefix('/images/logo-icon.svg')}
              className="logo-icon"
            />
            <div>Campaign Settings</div>
          </DialogTitle>
          <DialogContent width="100%" style={{ paddingTop: '20px' }}>
            <div className="partner-dashboard">
              <CampaignSetupForm
                campaign={addCampaign}
                onComplete={() => {
                  setAddCampaign(null);
                  actions.loadCampaignList();
                }}
                modal
              />
            </div>
          </DialogContent>
        </Dialog>
      ) : null}
    </>
  );
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    siteConfig: state.commonData.siteConfig,
    authUser: state.authUser,
    partnerData: state.partnerData
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch),
      loadCampaignList: bindActionCreators(
        partnerDataActions.loadCampaignList,
        dispatch
      )
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SCPartnerDashboard);
