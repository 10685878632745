import React, { useEffect, useRef, useState } from 'react';
import { navigate } from 'gatsby-link';
import { useQueryParam } from 'gatsby-query-params';
import { connect, Provider } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withPrefix } from 'gatsby-link';
import moment from 'moment';
import StaticDateRangePicker from '@mui/lab/StaticDateRangePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  TextField
} from '@mui/material';

import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Switch from '@mui/material/Switch';

import { round } from '../../api/offerCalc';
import { getSessionStorage, setSessionStorage } from '../../api/dataApi';
import { saveUserCampaign } from '../../api/partnerDataApi';

import * as webSocketActions from '../../redux/actions/webSocketActions';

import { getProgramList } from '../PartnerProgram';

import './index.css';

function CampaignSetupForm({
  siteConfig,
  webSocket,
  actions,
  authUser,
  campaign,
  onComplete,
  modal,
  partnerData
}) {
  const programList = getProgramList(siteConfig);
  const [editCampaign, setEditCampaign] = useState(null);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [formSaveError, setFormSaveError] = useState(null);

  useEffect(() => {
    console.log('editCampaign', editCampaign);
  }, [editCampaign]);

  useEffect(() => {
    if (campaign) {
      setEditCampaign({
        ...campaign,
        includeDates: campaign.DateStart || campaign.DateEnd,
        DateStart: campaign.DateStart ? moment(campaign.DateStart) : null,
        DateEnd: campaign.DateEnd ? moment(campaign.DateEnd) : null
      });
    } else {
      setEditCampaign({});
    }
  }, []);

  function checkFormDisabled() {
    return (
      !editCampaign ||
      !editCampaign.CampaignName ||
      !editCampaign.PromoCode ||
      (editCampaign.includeDates &&
        (!editCampaign.DateStart ||
          !editCampaign.DateEnd ||
          !editCampaign.DateStart._isValid ||
          !editCampaign.DateEnd._isValid))
    );
  }

  return authUser && authUser.User && authUser.User.Token && editCampaign ? (
    <div className={'campaign-setup-form'}>
      {!modal ? (
        <>
          <h2>
            {editCampaign.UserCampaignID ? 'Campaign Settings' : 'Add Campaign'}
          </h2>
          <p className="directions">
            <strong>
              The {siteConfig.brand} Partner Program is tracked under individual
              campaigns. Create and promote as many campaigns as you'd like, but
              you can only have one campaign running at a time.
              <br />
              <br />
              You'll earn a commission when a user enters your campaign promo
              code for a qualifying order while checking out.
              <br />
              <br />
              {partnerData &&
              partnerData.campaignList &&
              partnerData.campaignList.length
                ? 'You may edit your campaign settings using the form below.'
                : 'To get started on your first campaign, enter the details below.'}
            </strong>
          </p>
        </>
      ) : null}

      <TextField
        fullWidth
        autoFocus
        required
        label={'Promo Code'}
        placeholder={
          'The promo code users enter to give you credit for their order'
        }
        value={editCampaign.PromoCode}
        helperText={'Numbers, letters and special characters only'}
        onChange={(event) =>
          setEditCampaign({
            ...editCampaign,
            PromoCode: event.target.value
              ? String(event.target.value).toLowerCase().split(' ').join('')
              : ''
          })
        }
      />

      <TextField
        fullWidth
        autoFocus
        required
        label={'Campaign Name'}
        placeholder={'This is displayed to users when they use your promo code'}
        value={editCampaign.CampaignName}
        onChange={(event) =>
          setEditCampaign({ ...editCampaign, CampaignName: event.target.value })
        }
      />

      <div className="dates">
        <FormControlLabel
          control={
            <Switch
              checked={editCampaign.includeDates}
              onChange={(event) => {
                setEditCampaign({
                  ...editCampaign,
                  includeDates: event.target.checked
                });
              }}
            />
          }
          label="Add a start and end date"
        />

        {editCampaign.includeDates ? (
          <>
            <div className="date-field">
              <label>Start Date</label>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <StaticDateRangePicker
                  orientation="landscape"
                  openTo="day"
                  value={
                    editCampaign.DateStart
                      ? [editCampaign.DateStart, editCampaign.DateEnd]
                      : [null, null]
                  }
                  views={['day']}
                  disableHighlightToday={true}
                  disablePast
                  onChange={(dates) => {
                    setEditCampaign({
                      ...editCampaign,
                      DateStart: moment(dates[0]).startOf('day'),
                      DateEnd: moment(dates[1]).startOf('day')
                    });
                  }}
                />
              </LocalizationProvider>

              <div className="selected-dates">
                {editCampaign.DateStart && editCampaign.DateEnd
                  ? moment(editCampaign.DateStart).format('MM/DD/yyyy') +
                    ' - ' +
                    moment(editCampaign.DateEnd).format('MM/DD/yyyy')
                  : 'Select a date range above'}
              </div>
            </div>
          </>
        ) : null}
      </div>

      {/* 
      <div className="program-list">
        <h3>Partner Program*</h3>

        <p>
          <strong>
            Select the program you'd like to enroll in for this partnership.{' '}
          </strong>
        </p>

        <div
          className={
            'program' + (editCampaign.PartnerProgramID === 1 ? ' selected' : '')
          }>
          <Button
            onClick={() =>
              setEditCampaign({ ...editCampaign, PartnerProgramID: 1 })
            }>
            <h4>Referral Partner</h4>
            <h5>Who it's for:</h5>
            <p>
              Partners who want to earn money by sending traffic to{' '}
              {siteConfig.brand}.
            </p>

            <h5>Cost: Free</h5>
            <p>
              We pay you when users sign up for{' '}
              {siteConfig.id === 'sc'
                ? 'electric plans'
                : 'Internet or electric plans'}{' '}
              on our website using your partner code.
            </p>

            <h5>What you get:</h5>
            <ul>
              <li>
                A referral link with your partner code to use in marketing
                campaigns/social media posts.
              </li>
              <li>
                Online access to your partner dashboard showing traffic you've
                sent and commissions earned.
              </li>
              <li>Media kit for use in social media posts and ad campaigns.</li>
            </ul>
          </Button>
        </div>

        <div
          className={
            'program' + (editCampaign.PartnerProgramID === 2 ? ' selected' : '')
          }>
          <Button
            onClick={() =>
              setEditCampaign({ ...editCampaign, PartnerProgramID: 2 })
            }>
            <h4>Co-branding Partner</h4>
            <h5>Who it's for:</h5>
            <p>
              Partners who want to integrate the {siteConfig.brand} checkout
              into their website.
            </p>
            <h5>Cost: $9.95/mo</h5>
            <p>
              We pay you when users sign up for{' '}
              {siteConfig.id === 'sc'
                ? 'electric plans'
                : 'Internet or electric plans'}{' '}
              through your website.
            </p>
            <h5>What you get:</h5>
            <ul>
              <li>Everything a referral partner gets plus...</li>
              <li>
                Website widget and/or WordPress plugin to embed the{' '}
                {siteConfig.brand} checkout process directly into your website.
              </li>
              <li>Tech support during initial setup.</li>
            </ul>
          </Button>
        </div>
      </div> */}

      <div className="buttons">
        {checkFormDisabled() || formSaveError ? (
          <div className={'validation' + (formSaveError ? ' alert' : '')}>
            {formSaveError
              ? formSaveError
              : 'Make sure all required fields are completed above.'}
          </div>
        ) : null}
        <Button
          variant="contained"
          disabled={checkFormDisabled()}
          color="primary"
          onClick={() => {
            setFormSaveError(null);
            actions.pageLoading(true, 'Saving...');
            saveUserCampaign({ ...editCampaign }).then((result) => {
              actions.pageLoading(false);
              if (result) {
                if (result.UserCampaignID > 0) {
                  if (onComplete) {
                    onComplete();
                  }
                } else {
                  setFormSaveError(result.AppMessage);
                }
              } else {
                setFormSaveError(
                  <>
                    An error occurred while saving your campaign. Please try
                    again later.
                  </>
                );
              }
            });
          }}>
          {editCampaign.UserCampaignID > 0 ? 'Save' : 'Continue'}
        </Button>

        {partnerData &&
        partnerData.campaignList &&
        partnerData.campaignList.length ? (
          <Button style={{ marginLeft: '20px' }} onClick={() => onComplete()}>
            Cancel
          </Button>
        ) : null}
      </div>
    </div>
  ) : null;
}

function mapStateToProps(state) {
  return {
    webSocket: state.webSocket,
    serviceAddress: state.serviceAddress,
    siteConfig: state.commonData.siteConfig,
    authUser: state.authUser,
    partnerData: state.partnerData
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CampaignSetupForm);
